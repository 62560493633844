<template>
  <div class="content flex flex-column">
    <h1>Tint History</h1>

    <div class="flex flex-col gap-4">
      <div class="flex items-center justify-between gap-2 w-100">
        <div class="flex gap-8">
          <date-range
            v-model="filters"
            @input="fetchRecords()"
          />

          <div class="flex flex-column gap-1">
            <div>Type:</div>
            <button-select
              v-model="filters.tint_type"
              :options="options.tint_type"
              size="sm"
              @input="fetchRecords()"
            />
          </div>

          <div class="flex flex-column gap-1">
            <div>Error:</div>
            <button-select
              v-model="filters.error_type"
              :options="options.error_type"
              size="sm"
              @input="fetchRecords()"
            />
          </div>

          <div class="flex flex-column gap-1">
            <div>Colors:</div>
            <v-select
              class="color-multiselect"
              v-model="filters.colors"
              :reduce="color => color.color_id"
              label="color_description"
              :options="options.colors"
              :closeOnSelect="false"
              multiple
              @input="fetchRecords()"
            >
              <template #option="{ color_description, color_hex }">
                <mask-display
                  :hex="color_hex"
                  :text="color_description"
                />
              </template>
            </v-select>
          </div>

        </div>
        <div class="flex gap-2">
          <b-button
            size="sm"
            variant="danger"
            class="text-nowrap d-none"
            @click="purgeRecords()"
          >
            Purge
          </b-button>

          <b-button
            variant="primary"
            size="sm"
            @click="openMixing()"
          >
            Corob
          </b-button>

          <b-button
            size="sm"
            variant="primary"
            class="text-nowrap"
            @click="fetchAll()"
          >
            Refresh
          </b-button>
        </div>
      </div>

      <div class="font-bold">
        Showing {{ records.length | format }} / {{ recordCount | format }} record(s)
      </div>

      <table class="table table-google table-highlight table-records">
        <thead>
          <tr>
            <th>ID</th>
            <th>Date</th>
            <th>Record</th>
            <th>Amount</th>
            <th class="text-nowrap">Can size</th>
            <th class="text-nowrap">Min wax</th>
            <th>Recipe</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="isLoading || records.length === 0">
            <td colspan="8">
              <div class="font-bold">
                <span v-if="isLoading">Loading..</span>
                <span v-else-if="records.length === 0">No records found</span>
              </div>
            </td>
          </tr>

          <tr v-for="record in records" :key="record.id">
            <td class="row-fit">{{ record.id }}</td>
            <td class="row-fit text-nowrap">
              {{ formatStandardDate(record.tint_date)?.slice(0, 16) }}
            </td>
            <td class="row-fit text-nowrap">
              <div v-if="record.tint_type==='COLOR'" class="d-flex gap-4 items-center">
                <mask-display
                  :hex="record.color_hex"
                  :text="record.color_id ? record.color_description : record.tint_description"
                  style="width: 150px;"
                />
              </div>
              <div v-else-if="record.tint_type==='PURGE'">
                Nozzle Purge
              </div>
              <div v-else>
                Unknown record
              </div>
            </td>
            <td class="row-fit text-right">
              <div v-if="record.tint_type==='COLOR'">
                {{ record.tint_amount }}x
              </div>
            </td>
            <td class="row-fit text-right text-nowrap">
              <div
                v-if="record.tint_type==='COLOR'"
                :class="{ 'text-danger': record.tint_cansize < record.color_min_wax }"
              >
                {{ record.tint_cansize | format }} g
              </div>
            </td>
            <td class="row-fit text-right text-nowrap">
              <div v-if="record.tint_type==='COLOR'">
                {{ record.color_min_wax | format }} g
              </div>
            </td>
            <td>
              <div class="d-flex gap-8 text-nowrap">
                <div
                  v-for="colorant in record.tint_colorants"
                  :key="`colorant_${record.id}_${colorant.colorant_code}`"
                  class="d-flex gap-2"
                  v-b-tooltip.hover
                  :title="colorant.colorant_amount"
                >
                  <span class="color-box" :class="colorant.colorant_code.toLowerCase()"></span>
                  <span>
                    <span>{{ colorant.colorant_code }}</span>
                    <span :class="{ 'text-danger': colorant.colorant_amount < 0.01 }">
                      {{ colorant.colorant_amount | round }} g
                    </span>
                  </span>
                </div>
              </div>
            </td>
            <td class="row-fit">
              <div class="flex gap-2">
                <b-button
                  v-if="record.tint_type === 'COLOR'"
                  size="sm"
                  variant="primary"
                  class="text-nowrap"
                  @click="reprintRecord(record)"
                >
                  Reprint
                </b-button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>

      <b-pagination
        v-model="filters.page"
        :total-rows="recordCount"
        :per-page="filters.per_page"
        @input="fetchRecords(true)"
        :disabled="isLoading"
      ></b-pagination>
    </div>

    <mixing-modal
      v-if="mixingModal.showModal"
      :data="mixingModal"
      :filters="filters"
      @apply="onMixingApply"
    />

    <recipe-modal
      v-if="recipeModal.showModal"
      :data="recipeModal"
    />
  </div>
</template>

<script>
import { formatStandardDate } from '@/helpers';

const ButtonSelect = () => import('@/components/global/ButtonSelect.vue');
const DateRange = () => import('@/components/invoices/DateRange.vue');
const MixingModal = () => import('@/components/colors/MixingModal.vue');
const RecipeModal = () => import('@/components/design/RecipeModal.vue');
const MaskDisplay = () => import('@/components/designs/MaskDisplay.vue');

export default {
  name: 'TintHistory',
  components: {
    ButtonSelect,
    DateRange,
    MixingModal,
    RecipeModal,
    MaskDisplay,
  },
  computed: {
    isLoading() {
      return this.loadingCount > 0;
    },
  },
  data() {
    return {
      loadingCount: 0,
      records: [],
      recordCount: 0,
      filters: {
        colors: [],
        date_from: null,
        date_to: null,
        tint_type: null,
        error_type: null,
        mixing_mode: 'inclusive',
        mixing_mode_disabled: true,
        page: 1,
        per_page: 200,
      },
      options: {
        tint_type: [
          { text: 'All', value: null },
          { text: 'Color', value: 'color' },
          { text: 'Purge', value: 'purge' },
        ],
        error_type: [
          { text: 'All', value: null },
          { text: 'Error', value: 'error' },
        ],
        colors: [],
      },
      tintTypeBadge: {
        color: 'primary',
        purge: 'light',
      },
      mixing: {
        french: [],
        bekro: [],
      },
      mixingModal: {
        showModal: false,
        mixing: {},
      },
      recipeModal: {
        showModal: false,
        invoice: null,
      },
    };
  },
  methods: {
    fetchColors() {
      this.loadingCount++;
      this.$http
        .get('/tint/colors')
        .then((res) => {
          const fetchedColors = res.body.colors.map((color) => ({
            text: color.color_description,
            value: color.color_id,
            ...color,
          }));
          this.options.colors = fetchedColors;
        })
        .catch((err) => {
          this.$toast.error(`Failed to fetch colors: ${err.response.text}`);
        })
        .finally(() => {
          this.loadingCount--;
        });
    },
    fetchRecordsData() {
      this.loadingCount++;
      this.$http
        .get('/tint/records')
        .query(this.filters)
        .query({ 'colors[]': this.filters.colors })
        .query({ mixing_french: this.mixing.french.join(',') })
        .then((res) => {
          this.records = res.body.records;
        })
        .catch((err) => {
          this.$toast.error(`Failed to fetch records: ${err.response.text}`);
        })
        .finally(() => {
          this.loadingCount--;
        });
    },
    fetchRecordCount() {
      this.loadingCount++;
      this.$http
        .get('/tint/records_count')
        .query(this.filters)
        .query({ 'colors[]': this.filters.colors })
        .query({ mixing_french: this.mixing.french.join(',') })
        .then((res) => {
          this.recordCount = res.body.count;
        })
        .catch((err) => {
          this.$toast.error(`Failed to fetch record count: ${err.response.text}`);
        })
        .finally(() => {
          this.loadingCount--;
        });
    },
    fetchRecords(paginate) {
      paginate = paginate === undefined ? false : paginate;
      if (paginate === false) {
        this.fetchRecordCount();
        this.filters.page = 1;
      } else {
        setTimeout(() => {
          window.scrollTo(0, 0);
        }, 50);
      }
      this.fetchRecordsData();
    },
    fetchAll() {
      this.fetchRecords();
      this.fetchColors();
    },
    purgeRecords() {
      if (!confirm('Do you wish to purge all records? Records will be refreshed on next tint.')) {
        return;
      }
      this.loadingCount++;
      this.$http
        .delete('/tint/records')
        .then(() => {
          this.fetchAll();
        })
        .catch((err) => {
          this.$toast.error(`Failed to purge records: ${err.response.text}`);
        })
        .finally(() => {
          this.loadingCount--;
        });
    },
    reprintRecord(record) {
      this.loadingCount++;
      this.$http
        .post('/tint/reprint')
        .send({ tintId: record.id })
        .then(() => {
          // no-op
        })
        .catch((err) => {
          this.$toast.error(`Failed to reprint label: ${err.response.text}`);
        })
        .finally(() => {
          this.loadingCount--;
        });
    },
    formatStandardDate(standardDate) {
      return formatStandardDate(standardDate);
    },
    openMixing() {
      this.mixingModal.mixing = this.mixing;
      this.mixingModal.showModal = true;
    },
    onMixingApply(mixing) {
      this.mixing = mixing;
      this.fetchRecords();
    },
  },
  created() {
    this.fetchColors();
  },
};
</script>

<style lang="scss" scoped>
  .records {
    max-width: 1450px;
  }

  .table-records .badge {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80px;
    height: 20px;
  }

  .search-field {
    width: 250px;
  }

  .color-multiselect {
    width: 350px;
  }
</style>
